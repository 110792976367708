import { createTheme, SimplePaletteColorOptions } from "@mui/material/styles";
import { maxWidth } from "@mui/system";
import createBreakpoints, { Breakpoints } from "@mui/system/createTheme/createBreakpoints";

import { colors } from "./colors";

declare module "@mui/material/styles" {
    interface TypographyVariants {
        focused: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        focused?: React.CSSProperties;
    }
}
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        focused: true;
    }
}

declare module "@mui/material/styles" {
    interface CustomPalette {
        black: SimplePaletteColorOptions;
        white: SimplePaletteColorOptions;
        green: SimplePaletteColorOptions;
        greyColor: SimplePaletteColorOptions;
    }
    interface Palette extends CustomPalette {}
    interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        black: true;
        green: true;
        white: true;
        greyColor: true;
    }
}

const breakpointValues = {
    values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1588,
        xxl: 2100,
        phone: 0,
        tablet: 640,
        laptop: 1024,
        desktop: 1280,
        widescreen: 1920,
        ultrawide: 2100,
    },
};

const breakpoints: Breakpoints = createBreakpoints({
    ...breakpointValues,
});

const theme = createTheme({
    palette: {
        ...colors,
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
      input:-webkit-autofill,
      input:-webkit-autofill:hover, 
      input:-webkit-autofill:focus, 
      input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 100px transparent inset !important;
      },
        .website-main {
          background-color: ${colors.white.light};
          lineHeight: 143%;
          box-sizing: border-box;
        },
      `,
        },
        MuiContainer: {
            styleOverrides: {},
            variants: [
                {
                    props: { maxWidth: "xl", disableGutters: false },
                    style: {
                        "@media (min-width: 600px)": {
                            paddingRight: 48,
                            paddingLeft: 48,
                        },
                    },
                },
            ],
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
            },
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    background: "transparent",
                    fontFamily: "Euclid Circular B",
                    fontSize: "1rem",
                    lineHeight: "1.5rem",
                    fontStyle: "normal",
                    fontWeight: "500",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "12px 24px",
                    textDecoration: "none" as const,
                    textTransform: "none" as const,
                    minWidth: "fit-content",
                    borderRadius: "0",
                    boxShadow: "none",
                },
                contained: {
                    background: colors.secondary.main,
                    color: colors.white.main,
                    "&:hover": {
                        backgroundColor: colors.secondary.light,
                    },
                },
            },
            variants: [
                {
                    props: { variant: "contained", color: "greyColor" },
                    style: {
                        background: colors.greyColor.main,
                        color: colors.primary.main,
                        "&:hover": {
                            background: `${colors.greyColor.main}99`,
                        },
                    },
                },

                {
                    props: { variant: "contained", color: "green" },
                    style: {
                        background: colors.green.main,
                        color: colors.primary.main,
                        "&:hover": {
                            background: `${colors.green.main}99`,
                        },
                    },
                },
            ],
        },

        MuiLink: {
            styleOverrides: {
                root: {
                    fontFamily: "Euclid Circular B",
                    fontSize: "1.125rem",
                    color: colors.primary.main,
                    fontWeight: 600,
                    textDecoration: "none",
                },
            },
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: colors.primary.main,
                    fontWeight: 600,
                    fontSize: "1.125rem",
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    ".MuiFormLabel-root": {
                        fontSize: "1rem",
                    },
                    ".MuiOutlinedInput-input": {
                        fontSize: "1rem",
                    },

                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                            borderColor: colors.primary.main,
                        },
                    "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                        {
                            borderColor: colors.secondary.main,
                        },
                    "& .MuiInputLabel-formControl.Mui-focused": {
                        color: colors.secondary.main,
                    },

                    border: 0,
                    borderRadius: 0,
                    borderColor: colors.primary.main,
                    "& fieldset": {
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        border: `0.126rem solid ${colors.primary.main}`,
                        borderColor: colors.primary.main,
                    },
                    input: {
                        backgroundColor: "white",
                        "&::placeholder": {
                            color: colors.primary.main,
                        },
                    },
                    "& .MuiInputBase-multiline": {
                        backgroundColor: "white",
                        "&::placeholder": {
                            color: colors.primary.main,
                        },
                    },
                    "& .MuiSelect-select ": {
                        backgroundColor: "white",
                        "&::placeholder": {
                            color: colors.primary.main,
                        },
                    },
                },
            },
        },

        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: colors.primary.main,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    padding: "13px 24px",
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    border: `0.126rem solid ${colors.primary.main}`,
                    borderColor: colors.primary.main,
                    backgroundColor: "white",
                    borderRadius: 0,
                },
                standard: {
                    borderColor: colors.pink.main,
                    border: 0,
                },
            },
        },
    },

    breakpoints: breakpoints,
    typography: {
        fontFamily: "Euclid Circular B",
        fontSize: 18,

        h1: {
            fontSize: "2.5rem",
            fontFamily: "Goodall Pro",

            fontWeight: 600,
            fontStyle: "italic",
            lineHeight: "116%",

            [breakpoints.up("md")]: {
                fontSize: "4.75rem",
                fontFamily: "Goodall Pro",
                fontStyle: "italic",
                fontWeight: 600,
                lineHeight: "116%",
            },
        },
        h2: {
            color: colors.primary.main,
            fontSize: "1.75rem",
            fontFamily: "Goodall Pro",
            lineHeight: "116%",
            fontStyle: "italic",
            fontWeight: 600,

            [breakpoints.up("md")]: {
                color: colors.primary.main,
                fontSize: "3rem",
                fontFamily: "Goodall Pro",
                fontStyle: "italic",
                lineHeight: "116%",
                fontWeight: 600,
            },
        },
        h3: {
            color: colors.primary.main,
            fontFamily: "Euclid Circular B",
            fontSize: "1.3rem",
            fontWeight: "600",
            lineHeight: "120%",
            [breakpoints.up("md")]: {
                color: colors.primary.main,
                fontFamily: "Euclid Circular B",
                fontSize: "1.75rem",
                fontWeight: "600",
                lineHeight: "120%",
            },
        },
        h4: {
            fontFamily: "Euclid Circular B",
            fontWeight: 700,
            fontSize: "2.125rem",
            lineSpacing: "123.5%",
            letterSpacing: "0.25px",
        },
        h5: {
            fontFamily: "Euclid Circular B",
            fontSize: "1.5rem",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "133.4%",
        },
        h6: {
            fontFamily: "Euclid Circular B",
            fontSize: "1.25rem",
            fontWeight: 700,
            lineHeight: "160%",
            letterSpacing: "0.15px",
        },
        body1: {
            fontSize: "1.125rem",
            fontFamily: "Euclid Circular B",
            lineHeight: "150%",
        },
        body2: {
            fontSize: "1rem",
            fontFamily: "Euclid Circular B",
            lineHeight: "143%",
        },
        subtitle1: {
            fontFamily: "Euclid Circular B",
            fontSize: "1rem",
            fontWeight: 600,
            lineHeight: "175%",
            letterSpacing: "0.15px",
        },
        subtitle2: {
            fontFamily: "Euclid Circular B",
            fontSize: ".875rem",
            fontWeight: 600,
            color: "rgba(0, 0, 0, 0.60)",
            lineHeight: "157%",
            letterSpacing: "0.1px",
        },
        caption: {
            fontFamily: "Euclid Circular B",
            fontSize: ".875rem",
            lineHeight: "166%",
            color: colors.black.main,
        },
        button: {
            fontWeight: 700,
        },
    },
});

export default theme;
