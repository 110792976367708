globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"5c2342bb3158bdd4be10b89bbf01c6eb828f3a6d"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const nodeENV = process.env.NEXT_PUBLIC_NODE_ENV;

Sentry.init({
    dsn: "https://78af55bd40320fc4fb3255d6169787ab@o4506438744014848.ingest.sentry.io/4506559374884864",
    environment: nodeENV,
    enabled: process.env.VERCEL_ENV === "production",

    enableTracing: false,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
        Sentry.replayIntegration({
            // Additional Replay configuration goes in here, for example:
            maskAllText: true,
            blockAllMedia: true,
        }),
    ],
});
