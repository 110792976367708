export const colors = {
    black: {
        main: "#000000",
        light: "#333333",
        dark: "#000000",
        contrastText: "#FFFFFF",
        grey500: "#9E9E9E",
        grey800: "#00000099",
    },
    white: {
        main: "#FFF",
        light: "#FAFAFA",
        dark: "#000000",
        contrastText: "#000000",
    },
    primary: {
        main: "#02267E",
    },
    secondary: {
        main: "#026CFF",
        light: "#4D9EFF",
    },
    green: {
        main: "#13E0B9",
    },
    greyColor: {
        main: "#EBEBEB",
        light: "#FAFAFA",
    },
    pink: {
        main: "#E14C8B",
    },
};
