import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import type { AppProps } from "next/app";
import Script from "next/script";
import { useState } from "react";
import "../public/fonts/stylesheet.css";

//TODO: this one is used for game page, but that's not live yet and it is breaking the cards
//import "../public/assets/styles/css/main.min.css";

import theme from "components/Theme/theme";

// const theme = createTheme({
//     typography: {
//         allVariants: {
//             fontFamily: "Proxima Soft",
//         },
//     },
// });

export default function App({ Component, pageProps }: AppProps) {
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Script
                    id="connexys-form-script"
                    src={`${process.env.NEXT_PUBLIC_CONNEXYS_URL}resource/cxsrec__cxsForm`}
                    type="text/javascript"
                    onLoad={() => {
                        setIsScriptLoaded(true);
                    }}
                />
                <Component {...pageProps} isScriptLoaded={isScriptLoaded} />
            </LocalizationProvider>
        </ThemeProvider>
    );
}
